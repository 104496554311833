import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import BannerGeneric from '../components/BannerGeneric'
import { useSlack } from '../components/hooks'
import Layout from '../components/layout'
import Lists from '../components/Lists'
import { Seo } from '../components/Seo'
import { BB_BLOGS } from '../utils/constants'
import { IBlogPost } from '../utils/types'

interface BlogsProps {
  data: {
    allContentfulBlogPost: {
      nodes: IBlogPost[]
    }
  }
}
const Journals: React.FC<BlogsProps> = ({ data }) => {
  const {
    allContentfulBlogPost: { nodes },
  } = data
  useSlack('Journals')
  return (
    <>
      <Layout>
        <Seo title="Blogs" />
        <BannerGeneric
          title="Journals"
          description="Our Doctors are creative writers. Please support."
          img={`https://${nodes[0].heroImage.file.url}`}
        />
        {nodes.length && <Lists data={nodes} type={BB_BLOGS} />}
      </Layout>
    </>
  )
}

const blogsQuery = graphql`
  query BlogsQuery {
    allContentfulBlogPost {
      nodes {
        id
        slug
        title
        description {
          childMarkdownRemark {
            excerpt(pruneLength: 75)
          }
        }
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 900
                height: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        heroImage {
          file {
            url
          }
        }
        author {
          name
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 65
                  height: 65
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery query={blogsQuery} render={data => <Journals data={data} />} />
)
