import React from 'react'
import Blogs from './Blogs'
import { BB_BLOGS } from '../utils/constants'
import { IBlogPost } from '../utils/types'

interface ListsProps {
  data: IBlogPost[]
  type: string
}

const Lists: React.FC<ListsProps> = ({ data, type }) => {
  return (
    <section id="blogs" className="major">
      <div className="inner">
        <div className="content">
          <section id="subscribe">
            <div className="inner">
              <section>
                <div className="box alt">
                  <div className="grid-wrapper">
                    {data.map((item: IBlogPost) => {
                      return (
                        <div className="col-4" key={item.id}>
                          {type === BB_BLOGS && <Blogs data={item} />}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Lists
