import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { IBlogPost } from '../utils/types'
interface BlogsProps {
  data: IBlogPost
}
const Blogs: React.FC<BlogsProps> = ({ data }) => {
  return (
    <div className="blog-list">
      <Link to={`/blog/${data.slug}`}>
        <div className="image fit">
          <GatsbyImage
            image={getImage(data.heroImage?.localFile as any)}
            alt={data.title as string}
            className="list-image"
          />
          <div
            className="listOverlay"
            dangerouslySetInnerHTML={{
              __html: data?.description?.childMarkdownRemark.excerpt,
            }}
          ></div>
          <span className="overlayAuthor">
            <GatsbyImage
              image={getImage(data.author.image.localFile as any)}
              alt={data.title as string}
              className="authoroverlay__Container__image"
            />
          </span>
        </div>
      </Link>
    </div>
  )
}

export default Blogs
